import $ from "jquery";

const alertMsg = (msg, callbackfn, msgtype) => {
    if (pageType() == 'phone') {
        ElMessageBox({
            customClass: 'mobileMessageAlert',
            confirmButtonClass: 'mobileMessageSure',
            title: '提示',
            message: msg,
            showCancelButton: false,
            confirmButtonText: '确定',
            type: msgtype,
            center: true,
            lockScroll: false,
            callback: (action) => {
                if(callbackfn){
                    callbackfn()
                }
            }
        })
    } else {
        ElMessageBox({
            title: '提示',
            message: msg,
            showCancelButton: false,
            confirmButtonText: '确定',
            type: msgtype,
            center: true,
            lockScroll: false,
            callback: (action) => {
                if(callbackfn){
                    callbackfn()
                }
            }
        })
    }
}

// 横幅提示(备注：已增加 white-space: nowrap 样式)
const topMsg = (msg, msgtype) => {
    if (pageType() == 'phone') {
        ElMessage({ customClass: 'mobileMessage', type: msgtype, message: msg })
    } else {
        ElMessage({ customClass: 'pcMessage', type: msgtype, message: msg })
    }
}

const confirmMsg = (msg, resolve, reject, msgtype,actionfn) => { //actionfn 暂用于带复选框的弹框提示使用,默认可不传（点击确定或取消后，关闭弹框前执行的方法）
    if (!resolve) {
        resolve = () => {}
    }
    if (!reject) {
        reject = () => {}
    }
    if (pageType() == 'phone') {
        ElMessageBox({
            title: '提示',
            message: msg,
            dangerouslyUseHTMLString: true,
            customClass: 'mobileMessageAlert',
            confirmButtonClass: 'mobileMessageSure',
            cancelButtonClass: 'mobileMessageCancel',
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: msgtype,
            center: true,
            lockScroll: false,
            beforeClose:(action, instance, done)=>{
                if(!actionfn){
                    done()
                }else{
                    actionfn(action, instance, done)
                }
            },
        }).then(() => {
            resolve()
            return true
        }).catch(() => {
            reject()
            return false
        })
    } else {
        ElMessageBox({
            title: '提示',
            message: msg,
            dangerouslyUseHTMLString: true,
            customClass: 'pcMessageAlert',
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: msgtype,
            center: true,
            lockScroll: false,
            beforeClose:(action, instance, done)=>{
                if(!actionfn){
                    done()
                }else{
                    actionfn(action, instance, done)
                }
            },
            // beforeClose: (action, instance, done) => {
            //     if (action === 'confirm') {
            //         instance.confirmButtonLoading = true
            //         instance.confirmButtonText = 'Loading...'
            //         setTimeout(() => {
            //             done()
            //             setTimeout(() => {
            //                 instance.confirmButtonLoading = false
            //             }, 300)
            //         }, 3000)
            //     } else {
            //         done()
            //     }
            // },
        }).then(() => {
            resolve()
            return true
        }).catch(() => {
            reject()
            return false
        })
    }
}

//带复选框的弹框提示
const checkBoxConfirm = (tips,checkboxtext,resolve,rejeck,tipOrlabelStyle)=> {
    let tipstyle = ""
    let labelstyle = ""
    if(tipOrlabelStyle){
        if(tipOrlabelStyle.tipstyle){ //tipstyle 上面的tips文字样式
            tipstyle = tipOrlabelStyle.tipstyle
        }
        if(tipOrlabelStyle.labelstyle){ //labelstyle 下面勾选框文字样式
            labelstyle = tipOrlabelStyle.labelstyle
        }
    }
    let msg = h('div', null, [
        h('p',{style:"text-align:left;"+tipstyle}, tips),
        h('div', {class: 'confirmMsg-checkbox' ,style:'display: flex;align-items: center;justify-content: center;margin-top: 5px;'},[
            h('input', {id:'confirmSureKnow',type: 'checkbox', name:'checkbox',style:'margin-right: 3px;cursor: pointer;'}, ),
            h('label', {for: 'confirmSureKnow',style:"font-weight:bolder; cursor: pointer;"+labelstyle},checkboxtext),
        ])
    ])

    confirmMsg(msg, ()=>{
        if(!resolve){
            resolve = ()=>{}
        }
        resolve()
    }, ()=>{
        if(!rejeck){
            rejeck = ()=>{}
        }
        rejeck()
    },null,(action, instance, done)=>{
        if (action === 'confirm') {
            const state = $("#confirmSureKnow").is(':checked')
            if(state){
                done()
            }else{
                topMsg("请先勾选"+checkboxtext,"warning")
            }
        }else{
            done()
        }
    })
}

const jumplink = (msg, type, link) => {
    let path = ''
    if (import.meta.env.VITE_APP_BASE_CONTEXT == 'WHTAXIExam') {
        if (type == 'pc') {
            path = '#/person/extra/' + link
        } else {
            path = '#/person/m_' + link
        }
    } else {
        if (type == 'pc') {
            path = '#/person/' + link
        } else {
            path = '#/person/m_' + link
        }
    }
    alertMsg(msg, () => {
        window.location.href = path
    }, 'warning')
}
// PC端验证信息完善情况 noturn:用于报名页面人脸识别不跳转
export const checkCanSignup = (personinfo, type) => {
    let linkList = {}
    if (type == 'pc') {
        linkList = {
            base: 'base',
            extra: 'extra',
            photo: 'photo',
            drive: 'photo'
        }
    } else {
        linkList = {
            base: 'personInfo',
            extra: 'personExamInfo',
            photo: 'personPhoto',
            drive: 'personDrive'
        }
    }
    if (!personinfo.cardno) {
        jumplink('请绑定基本信息！', type, linkList.base)
        return false
    }

    if (import.meta.env.VITE_APP_BASE_CONTEXT == 'WHTAXIExam') {
        if (type == 'pc') {
            linkList = {
                base: '1',
                extra: '2',
                photo: '3',
                drive: '3'
            }
        }
        // if ( personinfo.facecheck != 1 && personinfo.facecheck != 8) {
        //     jumplink("上传照片并人脸识别通过后才可以报名考试！", type, linkList.base);
        //     return;
        // }
        if (!personinfo.picinfosrc) {
            jumplink('请上传个人头像！', type, linkList.photo)
            return false
        }
        if (!personinfo.idcardphotosrc) {
            jumplink('请上传证件照人像面！', type, linkList.photo)
            return
        }
        if (!personinfo.idcardbackphotosrc) {
            jumplink('请上传证件照国徽面！', type, linkList.photo)
            return
        }
        if (!personinfo.drivinglicfirsrc) {
            jumplink('请上传机动车驾驶证正页！', type, linkList.drive)
            return false
        }
        if (!personinfo.drivinglicsecsrc) {
            jumplink('请上传机动车驾驶证副页！', type, linkList.drive)
            return false
        }
        if (!personinfo.health) {
            jumplink('请选择健康状况！', type, linkList.extra)
            return false
        }
        if (!personinfo.degree) {
            jumplink('请选择学历！', type, linkList.extra)
            return false
        }
        if (!personinfo.firstissuedate) {
            jumplink('请选择初领机动车驾驶证日期！', type, linkList.extra)
            return false
        }
        if (!personinfo.drivinglicence) {
            jumplink('请选择机动车驾驶证准驾车型！', type, linkList.extra)
            return false
        }
    } else {
        if (personinfo.cardno && personinfo.cardtype && personinfo.cardtype == '0') {
            if (!personinfo.policecheck || (personinfo.policecheck && personinfo.policecheck != '4' && personinfo.policecheck != '7')) {
                jumplink('身份信息暂未验证通过，请重新核对身份信息后保存或上传身份附件提交人工验证！', type, linkList.base)
                return false
            }
        }
        if (!personinfo.nationals) {
            jumplink('请选择民族！', type, linkList.extra)
            return false
        }
        if (!personinfo.education) {
            jumplink('请选择学历！', type, linkList.extra)
            return false
        }
        if (!personinfo.companyname) {
            jumplink('请填写工作单位！', type, linkList.extra)
            return false
        }
        if (!personinfo.worktransportyears) {
            jumplink('请填写累计城市客运工作年限！', type, linkList.extra)
            return false
        }
        if (!personinfo.registeraddress) {
            jumplink('请选择工商（法人）注册地！', type, linkList.extra)
            return false
        }
        if (!personinfo.organizationcode) {
            jumplink('请填写统一社会信用代码！', type, linkList.extra)
            return false
        }
        if (!personinfo.photosrc) {
            jumplink('请上传个人头像！', type, linkList.photo)
            return false
        }
        if (!personinfo.idcardfront) {
            if (personinfo.cardtype == '0' && personinfo.policecheck && personinfo.policecheck == '7') {
            } else {
                jumplink('请上传证件照人像面！', type, linkList.photo)
                return false
            }
        }
        if (!personinfo.idcardback) {
            if (personinfo.cardtype == '0' && personinfo.policecheck && personinfo.policecheck == '7') {
            } else {
                jumplink('请上传证件照国徽面！', type, linkList.photo)
                return false
            }
        }
        if (!personinfo.examprove) {
            jumplink('请上传参加考核证明附件！', type, linkList.photo)
            return false
        }
    }

    return true
}

export const checkCanSignupByAG = (personinfo, type) => {
    let linkList = {}
    if (type == 'pc') {
        linkList = {
            base: 'base',
            extra: 'extra',
            photo: 'photo',
            drive: 'photo'
        }
    } else {
        linkList = {
            base: 'personInfo',
            extra: 'personExamInfo',
            photo: 'personPhoto',
            drive: 'personDrive'
        }
    }

    // console.log(personinfo.cardno)
    // console.log(personinfo.schoolname)
    // console.log(personinfo.graduationdate)
    // console.log(personinfo.education)
    // console.log(personinfo.schoolname)
    // console.log(personinfo.worktransportyears)
    // console.log(personinfo.professionjob)
    // console.log(personinfo.professiontitle)
    // console.log("personinfo.companyname",personinfo.companyname)
    // console.log("personinfo.registeraddress",personinfo.registeraddress)
    // console.log(personinfo.organizationcode)
    // console.log(personinfo.photosrc)
    // console.log(personinfo.idcardfrontsrc)
    // console.log(personinfo.idcardbacksrc)

    if (!personinfo.cardno) {
        alertMsg('请绑定基本信息！', () => {
            window.location.href = "#/person/base";
        }, 'warning')
        return false
    }

    if (type == 'pc') {
        linkList = {
            base: '1',
            extra: '1',
            photo: '2',
            drive: '3'
        }
    }
    // if ( personinfo.facecheck != 1 && personinfo.facecheck != 8) {
    //     jumplink("上传照片并人脸识别通过后才可以报名考试！", type, linkList.base);
    //     return;
    // }
    let msg = "请先完善并保存考试报名信息！"
    if (!personinfo.schoolname) {
        jumplinkByAG(msg, type, linkList.extra)
        return false
    }
    if (!personinfo.graduationdate) {
        jumplinkByAG(msg, type, linkList.extra)
        return false
    }
    if (!personinfo.education) {
        jumplinkByAG(msg, type, linkList.extra)
        return false
    }
    if (!personinfo.schoolname) {
        jumplinkByAG(msg, type, linkList.extra)
        return false
    }
    if (!personinfo.worktransportyears) {
        jumplinkByAG(msg, type, linkList.extra)
        return false
    }
    if (!personinfo.professionjob) {
        jumplinkByAG(msg, type, linkList.extra)
        return false
    }
    if (!personinfo.professiontitle) {
        jumplinkByAG(msg, type, linkList.extra)
        return false
    }

    if (!personinfo.companyname) {
        jumplinkByAG(msg, type, linkList.extra)
        return false
    }
    if (import.meta.env.VITE_APP_OUTPUTPATH != 'aggd'){
        if (!personinfo.registeraddress) {
            jumplinkByAG(msg, type, linkList.extra)
            return false
        }
        if (!personinfo.organizationcode) {
            jumplinkByAG(msg, type, linkList.extra)
            return false
        }
    }
    
    if (!personinfo.photosrc) {
        jumplinkByAG('请上传个人头像！', type, linkList.photo)
        return false
    }
    if (!personinfo.idcardfrontsrc) {
        jumplinkByAG('请上传证件照人像面！', type, linkList.photo)
        return false
    }
    if (!personinfo.idcardbacksrc) {
        jumplinkByAG('请上传证件照国徽面！', type, linkList.photo)
        return false
    }
    return true
}
const jumplinkByAG = (msg, type, link) => {
    let path = ''
    if (type == 'pc') {
        path = '#/person/extra/' + link
    } else {
        path = '#/person/m_' + link
    }
    alertMsg(msg, () => {
        console.log(path)
        //toggle.value = link
        window.location.href = path
    }, 'warning')
}

function pageType() {
    const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    if (flag) {
        return 'phone'
    } else {
        return 'pc'
    }
}

// 设置带过期时间的数据
export const setItemWithExpiration = (key, value, ttl) => {
    const now = new Date().getTime();
    const expirationTime = now + ttl;
    sessionStorage.setItem(key, JSON.stringify({
        value,
        expiresAt: expirationTime,
    }));
}

// 获取并检查数据是否过期
export const getItemWithExpiration = (key)=> {
    const item = JSON.parse(sessionStorage.getItem(key));
    if (!item || item.expiresAt < new Date().getTime()) {
        sessionStorage.removeItem(key);
        return null;
    }
    return item.value;
}

export { alertMsg, confirmMsg,checkBoxConfirm, topMsg, pageType }
